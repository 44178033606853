import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(-0.5),
  },
  root: {
    margin: theme.spacing(0.5),
  },
  label: {
    ...theme.typography.caption,
    textTransform: "uppercase",
  },
}));

export default useStyles;
