import React from "react";
import Chip from "@material-ui/core/Chip";
import useStyles from "./useStyles";

const ChipGroup = ({ labels }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {labels.map((label) => (
        <Chip
          key={label}
          label={label}
          size="small"
          classes={{ root: classes.root, labelSmall: classes.label }}
        />
      ))}
    </div>
  );
};

export default ChipGroup;
