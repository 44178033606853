import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  const tabletAndUp = theme.breakpoints.up("md");
  const tabletDown = theme.breakpoints.down("md");
  const mobileDown = theme.breakpoints.down("xs");

  return {
    root: {
      [tabletAndUp]: {
        display: "grid",
        gridTemplateColumns: "1fr 4fr 1fr",
        gridTemplateRows: "40vh auto",
        gridTemplateAreas: `"header header header" ". navbar ." ". main ."`,
        columnGap: theme.spacing(1),
      },
    },
    header: {
      display: "flex",
      [tabletAndUp]: {
        gridArea: "header",
      },
      [tabletDown]: {
        height: "max-content",
        minHeight: "40vh",
      },
    },
    navBar: {
      [tabletAndUp]: {
        gridArea: "navbar",
      },
    },
    body: {
      [tabletAndUp]: {
        gridArea: "main",
      },
      [tabletDown]: {
        margin: theme.spacing(0, 4),
      },
      [mobileDown]: {
        margin: theme.spacing(0, 1),
      },
    },
  };
});

export default useStyles;
