import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  const wideScreen = theme.breakpoints.up("lg");

  return {
    root: {
      [wideScreen]: {
        display: "grid",
        gridTemplateColumns: "1fr 5fr",
        gridTemplateAreas: `"title timeline"`,
        columnGap: theme.spacing(8),
      },
      padding: theme.spacing(8, 0),
    },
    title: {
      textAlign: "right",
      marginBottom: theme.spacing(4),
    },
  };
});

export default useStyles;
