import React from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import MarkdownRenderer from "components/MarkdownRenderer";

import useStyles from "./useStyles";

const DescriptionList = ({ description }) => {
  const classes = useStyles();

  return (
    <List dense disablePadding>
      {description.map((point) => (
        <ListItem key={point} disableGutters className={classes.item}>
          <ListItemIcon className={classes.icon}>
            <ArrowRightIcon />
          </ListItemIcon>
          <ListItemText>
            <MarkdownRenderer className={classes.text}>{point}</MarkdownRenderer>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

export default DescriptionList;
