import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  const mobileDown = theme.breakpoints.down("xs");

  return {
    root: {
      ...theme.typography.h1,
      [mobileDown]: {
        ...theme.typography.h2,
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.primary.main,
      width: "100%",
      padding: theme.spacing(4),
      boxSizing: "border-box",
    },
  };
});

export default useStyles;
