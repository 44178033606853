import React from "react";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import DescriptionList from "components/DescriptionList";
import ChipGroup from "components/ChipGroup";

const TimelineElement = ({ experience, classes }) => {
  return (
    <Accordion key={experience.title} classes={{ root: classes.container, rounded: classes.containerBorder }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ content: classes.summary }}>
        <div className={classes.mainText}>
          <Typography variant="h6">{experience.title}</Typography>
          <Typography variant="subtitle1">{experience.subtitle}</Typography>
        </div>
        <div className={classes.subText}>
          <Typography variant="overline">{experience.period}</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.detail}>
        <DescriptionList description={experience.description} />
        {experience.technologies ? (
          <>
            <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
              Technology Used
            </Typography>
            <ChipGroup labels={experience.technologies} />
          </>
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
};

export default TimelineElement;
