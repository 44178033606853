import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    "& > :not(:first-child)": {
      marginLeft: theme.spacing(4),
    },
  },
}));

export default useStyles;
