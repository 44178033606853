import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  const mobileDown = theme.breakpoints.down("xs");
  const tabletDown = theme.breakpoints.down("sm");
  const tabletAndUp = theme.breakpoints.up("sm");

  return {
    root: {
      [tabletAndUp]: {
        display: "grid",
        gridTemplateColumns: "1fr 5fr",
        gridTemplateAreas: `"image content"`,
        columnGap: theme.spacing(8),
        padding: theme.spacing(8, 0),
      },
    },
    contentWrapper: {
      gridArea: "content",
      [mobileDown]: {
        marginTop: theme.spacing(2),
      },
    },
    content: {
      ...theme.typography.body1,
      ...theme.palette.text.primary,
    },
    media: {
      height: "256px",
      [tabletDown]: {
        height: "196px",
      },
      borderRadius: "50%",
      gridArea: "image",
    },
  };
});

export default useStyles;
