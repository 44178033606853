import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(2, 0),
  },
  containerBorder: {
    borderRadius: "4px !important",
  },
  summary: {
    display: "grid",
    gridTemplateColumns: "3fr 2fr",
    gridTemplateAreas: `"mainText subText"`,
    columnGap: theme.spacing(1),
    "& > div": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  mainText: {
    gridArea: "mainText",
  },
  subText: {
    gridArea: "subText",
    textAlign: "right",
  },
  detail: {
    display: "flex",
    flexDirection: "column",
    "& > :not(:first-child)": {
      marginTop: theme.spacing(1),
    },
  },
}));

export default useStyles;
