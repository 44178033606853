import { createMuiTheme } from "@material-ui/core/styles";

const ACCENT_COLOURS = ["#ECCBD9", "#FFD6A5", "#C1D3FE", "#DEE2FF"];

export default createMuiTheme({
  palette: {
    primary: {
      main: ACCENT_COLOURS[Math.floor(Math.random() * ACCENT_COLOURS.length)],
    },
    text: {
      primary: "#1E1A1A",
      secondary: "#636363",
    },
  },
});
