import React from "react";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";

import SocialNetwork from "components/SocialNetwork";
import MarkdownRenderer from "components/MarkdownRenderer";

import useStyles from "./useStyles";

const AboutMeSection = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Grid
      id="about-me"
      container
      justify="center"
      alignItems="center"
      className={classes.root}
      direction={isMobile ? "column" : "row"}
    >
      <Grid container item justify={isMobile ? "center" : "flex-end"}>
        <Grid
          item
          component="img"
          className={classes.media}
          src="https://avatars.githubusercontent.com/flxffy"
          alt="me"
        />
      </Grid>
      <Grid container item direction="column" spacing={2} className={classes.contentWrapper}>
        <Grid item component={Link} href="#about-me" variant="h3" color="textPrimary">
          About Me
        </Grid>
        <Grid item component={MarkdownRenderer} className={classes.content}>
          I'm a final year Computer Science student at National University of Singapore. I enjoy binging
          k-dramas and getting my nails done!
        </Grid>
        <Grid item component={SocialNetwork} />
      </Grid>
    </Grid>
  );
};

export default AboutMeSection;
