import React from "react";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";

import Timeline from "components/Timeline";

import useStyles from "./useStyles";

const ExperienceSection = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isWideScreen = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Grid
      id="experience"
      container
      justify="center"
      alignItems="flex-start"
      className={classes.root}
      direction={isWideScreen ? "row" : "column"}
    >
      <Grid
        item
        component={Link}
        href="#experience"
        variant="h3"
        color="textPrimary"
        className={classes.title}
      >
        Experience
      </Grid>
      <Grid item component={Timeline} className={classes.timeline} />
    </Grid>
  );
};

export default ExperienceSection;
