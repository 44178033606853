import React from "react";
import ReactMarkdown from "react-markdown";

const MarkdownRenderer = ({ children, ...props }) => {
  return (
    <ReactMarkdown linkTarget="_blank" {...props}>
      {children}
    </ReactMarkdown>
  );
};

export default MarkdownRenderer;
