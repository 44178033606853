import React from "react";

import TimelineElement from "./TimelineElement";
import useStyles from "./useStyles";
import experiences from "data/experiences";

const Timeline = () => {
  const classes = useStyles();

  return (
    <div>
      {experiences.map((experience) => (
        <TimelineElement
          key={`${experience.title} ${experience.period}`}
          experience={experience}
          classes={classes}
        />
      ))}
    </div>
  );
};

export default Timeline;
