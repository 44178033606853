import React from "react";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import ResumePDF from "data/resume.pdf";

import useStyles from "./useStyles";

const NavBar = () => {
  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.root}>
      <Button href="#about-me">About Me</Button>
      <Button href="#experience">Experience</Button>
      <Button href={ResumePDF} target="_blank" rel="noreferrer">
        Resume
      </Button>
    </Grid>
  );
};

export default NavBar;
