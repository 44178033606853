import React from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";

import theme from "theme";
import Header from "components/Header";
import NavBar from "components/NavBar";
import AboutMeSection from "containers/AboutMeSection";
import ExperienceSection from "containers/ExperienceSection";

import useStyles from "./useStyles";

const App = () => {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <div className={classes.header}>
          <Header />
        </div>
        <div className={classes.navBar}>
          <NavBar />
        </div>
        <div className={classes.body}>
          <AboutMeSection />
          <ExperienceSection />
        </div>
      </div>
    </MuiThemeProvider>
  );
};

export default App;
