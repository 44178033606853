import React from "react";
import Typewriter from "typewriter-effect";

import useStyles from "./useStyles";

const Header = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typewriter
        onInit={(typewriter) => {
          typewriter.typeString(`Hello, I'm Ai${"\xa0"}Hui!`).start();
        }}
      />
    </div>
  );
};

export default Header;
