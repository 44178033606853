import React from "react";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from "@material-ui/icons/EmailRounded";

const AboutMeSection = () => (
  <Grid container>
    <IconButton href="https://github.com/flxffy" target="_blank" rel="noreferrer">
      <GitHubIcon />
    </IconButton>
    <IconButton href="https://www.linkedin.com/in/ai-hui-ong/" target="_blank" rel="noreferrer">
      <LinkedInIcon />
    </IconButton>
    <IconButton href="mailto:hello@aihui.dev">
      <EmailIcon />
    </IconButton>
  </Grid>
);

export default AboutMeSection;
